import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from "gatsby"

import Layout from '../components/Layout';

const useSEO = () => {
  const { contentfulComponentSeo } = useStaticQuery(
    graphql`
    query SEO {
      contentfulComponentSeo(name: {eq: "index"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED,
            placeholder: BLURRED,
            width: 600, 
            height: 600
          )
          id
          title
        }
        name
      }
    }
    `
  )
  return contentfulComponentSeo
}

const ContactUs = (props) => {
  const contentfulComponentSeo = useSEO()
  return (
    <Fragment>
      <Grid container alignItems="center" justify="center" spacing={3}>
        <Grid item >
          <Box m={3}>
            <Typography variant="p" component="h1">Contacto</Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
};

const ContactUsPage = (props) => (
  <Layout {...props}>
    <ContactUs />
  </Layout>
);


export default ContactUsPage